import React from 'react';
import { Signal, Wifi, Gift, Clock, CreditCard, Zap, CheckCircle, Smartphone, Tv } from 'lucide-react';

const Benefits: React.FC = () => {
  const benefits = [
    {
      icon: <Signal className="w-12 h-12 text-uai-teal group-hover:scale-110 transition-transform duration-300" />,
      title: 'Melhor cobertura',
      description: 'Sinal de qualidade em todo o Brasil com tecnologia 4G+ e 5G'
    },
    {
      icon: <Gift className="w-12 h-12 text-uai-teal group-hover:scale-110 transition-transform duration-300" />,
      title: 'Benefícios exclusivos',
      description: 'Descontos especiais e vantagens únicas para clientes UaiSat'
    },
    {
      icon: <Smartphone className="w-12 h-12 text-uai-teal group-hover:scale-110 transition-transform duration-300" />,
      title: 'App completo',
      description: 'Gerencie sua linha, faça recargas e acompanhe seu consumo'
    },
    {
      icon: <Clock className="w-12 h-12 text-uai-teal group-hover:scale-110 transition-transform duration-300" />,
      title: 'Atendimento 24h',
      description: 'Suporte especializado disponível quando você precisar'
    },
    {
      icon: <CreditCard className="w-12 h-12 text-uai-teal group-hover:scale-110 transition-transform duration-300" />,
      title: 'Pagamento flexível',
      description: 'Várias formas de pagamento e cashback exclusivo'
    },
    {
      icon: <Tv className="w-12 h-12 text-uai-teal group-hover:scale-110 transition-transform duration-300" />,
      title: 'UaiSat TV',
      description: 'Mais de 50 canais de TV ao vivo por apenas R$20/mês'
    }
  ];

  return (
    <section className="py-16 px-4 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Vantagens exclusivas UaiSat</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Descubra por que milhares de clientes escolhem a UaiSat como sua operadora
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {benefits.map((benefit, index) => (
            <div 
              key={index}
              className="bg-white p-8 rounded-2xl shadow-card hover:shadow-xl transition-all duration-300 group hover:-translate-y-1"
            >
              <div className="mb-6">{benefit.icon}</div>
              <h3 className="text-2xl font-bold mb-3">{benefit.title}</h3>
              <p className="text-gray-600 text-lg">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;