import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SEO_CONFIG } from '../config';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  keywords?: string;
}

export function useSEO(props?: SEOProps) {
  const location = useLocation();
  const seo = { 
    title: props?.title || SEO_CONFIG.DEFAULT_TITLE,
    description: props?.description || SEO_CONFIG.DEFAULT_DESCRIPTION,
    image: props?.image || SEO_CONFIG.SOCIAL_IMAGE,
    type: props?.type || 'website',
    keywords: props?.keywords || SEO_CONFIG.KEYWORDS
  };

  useEffect(() => {
    // Update title
    document.title = seo.title;

    // Update meta tags
    const metaTags = {
      'description': seo.description,
      'keywords': seo.keywords,
      'og:title': seo.title,
      'og:description': seo.description,
      'og:image': seo.image,
      'og:type': seo.type,
      'og:url': `${SEO_CONFIG.SITE_URL}${location.pathname}`,
      'twitter:title': seo.title,
      'twitter:description': seo.description,
      'twitter:image': seo.image,
      'twitter:url': `${SEO_CONFIG.SITE_URL}${location.pathname}`,
      'twitter:card': 'summary_large_image'
    };

    Object.entries(metaTags).forEach(([name, content]) => {
      // Update existing meta tags
      let meta = document.querySelector(`meta[property="${name}"]`) ||
                 document.querySelector(`meta[name="${name}"]`);
      
      if (meta) {
        meta.setAttribute('content', content);
      } else {
        // Create new meta tag if it doesn't exist
        meta = document.createElement('meta');
        if (name.startsWith('og:') || name.startsWith('twitter:')) {
          meta.setAttribute('property', name);
        } else {
          meta.setAttribute('name', name);
        }
        meta.setAttribute('content', content);
        document.head.appendChild(meta);
      }
    });

    // Update canonical URL
    let canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute('href', `${SEO_CONFIG.SITE_URL}${location.pathname}`);
    } else {
      canonical = document.createElement('link');
      canonical.setAttribute('rel', 'canonical');
      canonical.setAttribute('href', `${SEO_CONFIG.SITE_URL}${location.pathname}`);
      document.head.appendChild(canonical);
    }
  }, [location.pathname, seo]);
}