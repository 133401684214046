import React, { useState, useEffect } from 'react';
import { Tv, Play, Search, Filter, Info, ExternalLink, ArrowRight, Smartphone, Monitor, Laptop, Tablet } from 'lucide-react';
import { tvChannels, tvCategories } from '../lib/data';
import { Link } from 'react-router-dom';
import { useSEO } from '../lib/hooks/useSEO';

const TV: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [showChannelInfo, setShowChannelInfo] = useState<string | null>(null);

  // Set SEO metadata
  useSEO({
    title: 'UaiSat TV - Mais de 50 canais de TV ao vivo | UaiSat',
    description: 'Assista a mais de 50 canais de TV ao vivo em qualquer dispositivo. Adicione à sua assinatura UaiSat por apenas R$20/mês. Compatível com Smart TVs, smartphones, tablets e mais.',
    keywords: 'UaiSat TV, canais de TV, TV ao vivo, streaming de TV, TV online, TV por assinatura, TV móvel, TV em qualquer lugar',
    type: 'website'
  });

  const filteredChannels = tvChannels.filter(channel => {
    const matchesSearch = channel.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = selectedCategory ? channel.category === selectedCategory : true;
    return matchesSearch && matchesCategory;
  });

  const devices = [
    {
      name: 'Fire Stick Amazon',
      icon: <Monitor className="w-12 h-12 text-uai-teal" />,
      description: 'Transforme sua TV em Smart TV e acesse a UaiSat TV'
    },
    {
      name: 'Roku',
      icon: <Monitor className="w-12 h-12 text-uai-teal" />,
      description: 'Compatível com todos os dispositivos Roku'
    },
    {
      name: 'Dispositivos Móveis',
      icon: <Smartphone className="w-12 h-12 text-uai-teal" />,
      description: 'Assista no seu smartphone ou tablet Android e iOS'
    },
    {
      name: 'Set-top Box',
      icon: <Tv className="w-12 h-12 text-uai-teal" />,
      description: 'Compatível com diversos modelos de set-top box'
    },
    {
      name: 'Smart TV',
      icon: <Tv className="w-12 h-12 text-uai-teal" />,
      description: 'Acesse diretamente na sua Smart TV Samsung, LG, Sony e mais'
    },
    {
      name: 'Web Browser',
      icon: <Laptop className="w-12 h-12 text-uai-teal" />,
      description: 'Assista pelo navegador no seu computador ou notebook'
    }
  ];

  return (
    <div>
      {/* Hero Section with Video Background */}
      <section className="relative h-[500px] flex items-center overflow-hidden">
        <div className="absolute inset-0">
          <video 
            autoPlay 
            muted 
            loop 
            playsInline
            className="w-full h-full object-cover"
          >
            <source src="https://cdn.tv.br/wp-content/uploads/2025/04/VIDEO-SITE.mp4" type="video/mp4" />
            {/* Fallback image if video doesn't load */}
            <img
              src="https://images.unsplash.com/photo-1567690187548-f07b1d7bf5a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
              alt="UaiSat TV"
              className="w-full h-full object-cover"
            />
          </video>
          <div className="absolute inset-0 bg-gradient-to-r from-black/80 to-transparent" />
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4">
          <div className="max-w-2xl">
            <h1 className="text-5xl font-bold text-white mb-6">UaiSat TV</h1>
            <p className="text-xl text-white/90 mb-8">
              Mais de 50 canais de TV ao vivo para assistir quando e onde quiser. Adicione à sua assinatura por apenas R$20/mês.
            </p>
            <div className="flex flex-wrap gap-4">
              <a 
                href="#canais"
                className="bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-xl text-lg transition-all duration-300 inline-flex items-center"
              >
                <span>Ver canais</span>
                <ArrowRight className="ml-2 w-5 h-5" />
              </a>
              <Link 
                to="/#planos"
                className="bg-white text-uai-teal hover:bg-gray-100 font-bold py-4 px-8 rounded-xl text-lg transition-all duration-300 inline-flex items-center"
              >
                <span>Adicionar ao meu plano</span>
                <ArrowRight className="ml-2 w-5 h-5" />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Devices Section */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Seus clientes podem assistir de onde e quando quiser!</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              A UaiSat TV é compatível com diversos dispositivos, permitindo que você assista seus canais favoritos em qualquer lugar.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {devices.map((device, index) => (
              <div key={index} className="bg-white p-8 rounded-2xl shadow-card hover:shadow-xl transition-all duration-300 group hover:-translate-y-1 border border-gray-100">
                <div className="mb-6 transform group-hover:scale-110 transition-transform duration-300">
                  {device.icon}
                </div>
                <h3 className="text-2xl font-bold mb-3">{device.name}</h3>
                <p className="text-gray-600">{device.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CDNTV Partnership Section */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block px-4 py-2 bg-uai-light rounded-full text-uai-teal font-medium mb-6">
                Parceria Exclusiva
              </div>
              <h2 className="text-4xl font-bold mb-6">Em parceria com CDNTV</h2>
              <p className="text-lg text-gray-600 mb-6">
                A UaiSat se uniu à CDNTV, uma das maiores plataformas de streaming de TV do Brasil, para oferecer a melhor experiência de TV online para nossos clientes.
              </p>
              <p className="text-lg text-gray-600 mb-6">
                Com esta parceria, garantimos uma transmissão de alta qualidade, estável e com o melhor catálogo de canais disponíveis no mercado.
              </p>
              <div className="flex flex-wrap gap-4">
                <a 
                  href="https://cdn.tv.br"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center text-uai-teal hover:text-uai-dark font-medium"
                >
                  <span>Conhecer a CDNTV</span>
                  <ExternalLink className="w-4 h-4 ml-2" />
                </a>
              </div>
            </div>
            <div className="relative">
              <img 
                src="https://images.unsplash.com/photo-1593784991095-a205069470b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" 
                alt="CDNTV Partnership" 
                className="rounded-2xl shadow-xl"
              />
              <div className="absolute -bottom-6 -right-6 bg-white p-6 rounded-2xl shadow-xl">
                <div className="flex items-center">
                  <div className="bg-uai-light p-3 rounded-full mr-4">
                    <Play className="w-8 h-8 text-uai-teal" />
                  </div>
                  <div>
                    <p className="text-gray-500 text-sm">Powered by</p>
                    <p className="font-bold text-xl">CDNTV</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Channels Section */}
      <section id="canais" className="py-16 px-4 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">Canais disponíveis</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Confira todos os canais disponíveis na UaiSat TV
            </p>
          </div>

          <div className="mb-8 flex flex-col md:flex-row gap-4">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Buscar canais..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            </div>
            
            <div className="flex-shrink-0">
              <select
                value={selectedCategory || ''}
                onChange={(e) => setSelectedCategory(e.target.value || null)}
                className="w-full md:w-auto px-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              >
                <option value="">Todas as categorias</option>
                {tvCategories.map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {filteredChannels.map(channel => (
              <div 
                key={channel.id}
                className="bg-white p-4 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center text-center relative group"
              >
                <div className="w-16 h-16 flex items-center justify-center mb-3">
                  <img 
                    src={channel.logo} 
                    alt={channel.name}
                    className="max-w-full max-h-full object-contain"
                    onError={(e) => {
                      // Fallback if image fails to load
                      const target = e.target as HTMLImageElement;
                      target.src = 'https://via.placeholder.com/64x64?text=' + channel.name;
                    }}
                  />
                </div>
                <h3 className="font-medium text-sm">{channel.name}</h3>
                <p className="text-xs text-gray-500">{channel.category}</p>
                
                {channel.description && (
                  <button 
                    onClick={() => setShowChannelInfo(channel.id)}
                    className="absolute top-2 right-2 text-gray-400 hover:text-uai-teal transition-colors"
                  >
                    <Info className="w-4 h-4" />
                  </button>
                )}
                
                {showChannelInfo === channel.id && (
                  <div className="absolute inset-0 bg-white p-4 rounded-xl shadow-lg z-10 flex flex-col">
                    <button 
                      onClick={() => setShowChannelInfo(null)}
                      className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
                    >
                      &times;
                    </button>
                    <h4 className="font-bold text-sm mb-1">{channel.name}</h4>
                    <p className="text-xs text-gray-600 flex-grow">{channel.description}</p>
                    <span className="text-xs text-uai-teal mt-2">{channel.category}</span>
                  </div>
                )}
              </div>
            ))}
          </div>
          
          {filteredChannels.length === 0 && (
            <div className="text-center py-12">
              <p className="text-gray-500 mb-4">Nenhum canal encontrado com os filtros selecionados.</p>
              <button 
                onClick={() => {
                  setSearchTerm('');
                  setSelectedCategory(null);
                }}
                className="text-uai-teal hover:text-uai-dark"
              >
                Limpar filtros
              </button>
            </div>
          )}
        </div>
      </section>

      {/* How to Access Section */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">Como acessar</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Acesse a UaiSat TV de forma simples e rápida
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-2xl shadow-card border border-gray-100">
              <div className="w-12 h-12 bg-uai-light rounded-full flex items-center justify-center mb-6 text-2xl font-bold text-uai-teal">
                1
              </div>
              <h3 className="text-xl font-bold mb-3">Contrate um plano UaiSat</h3>
              <p className="text-gray-600">
                Escolha um dos nossos planos e adicione a opção UaiSat TV por apenas R$20/mês.
              </p>
            </div>

            <div className="bg-white p-8 rounded-2xl shadow-card border border-gray-100">
              <div className="w-12 h-12 bg-uai-light rounded-full flex items-center justify-center mb-6 text-2xl font-bold text-uai-teal">
                2
              </div>
              <h3 className="text-xl font-bold mb-3">Receba suas credenciais</h3>
              <p className="text-gray-600">
                Após a confirmação do pagamento, você receberá seu login e senha por e-mail.
              </p>
            </div>

            <div className="bg-white p-8 rounded-2xl shadow-card border border-gray-100">
              <div className="w-12 h-12 bg-uai-light rounded-full flex items-center justify-center mb-6 text-2xl font-bold text-uai-teal">
                3
              </div>
              <h3 className="text-xl font-bold mb-3">Acesse a plataforma</h3>
              <p className="text-gray-600">
                Acesse a plataforma UaiSat TV pelo site ou aplicativo e comece a assistir.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Devices Showcase */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">Dispositivos compatíveis</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              A UaiSat TV é compatível com diversos dispositivos
            </p>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center text-center">
              <Smartphone className="w-12 h-12 text-uai-teal mb-4" />
              <h3 className="font-medium">Smartphones</h3>
              <p className="text-sm text-gray-500 mt-2">Android e iOS</p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center text-center">
              <Tablet className="w-12 h-12 text-uai-teal mb-4" />
              <h3 className="font-medium">Tablets</h3>
              <p className="text-sm text-gray-500 mt-2">Android e iOS</p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center text-center">
              <Laptop className="w-12 h-12 text-uai-teal mb-4" />
              <h3 className="font-medium">Computadores</h3>
              <p className="text-sm text-gray-500 mt-2">Windows e Mac</p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center text-center">
              <Tv className="w-12 h-12 text-uai-teal mb-4" />
              <h3 className="font-medium">Smart TVs</h3>
              <p className="text-sm text-gray-500 mt-2">Samsung, LG, Sony</p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center text-center">
              <Monitor className="w-12 h-12 text-uai-teal mb-4" />
              <h3 className="font-medium">Fire TV Stick</h3>
              <p className="text-sm text-gray-500 mt-2">Amazon</p>
            </div>
            
            <div className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-300 flex flex-col items-center text-center">
              <Monitor className="w-12 h-12 text-uai-teal mb-4" />
              <h3 className="font-medium">Roku</h3>
              <p className="text-sm text-gray-500 mt-2">Todos os modelos</p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 px-4 bg-gradient-to-r from-uai-dark to-uai-teal text-white">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6">Adicione UaiSat TV ao seu plano</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Por apenas R$20/mês, tenha acesso a mais de 50 canais de TV ao vivo em qualquer lugar.
          </p>
          <Link 
            to="/#planos"
            className="bg-white text-uai-teal hover:bg-gray-100 font-bold py-4 px-8 rounded-xl text-lg transition-all duration-300 inline-flex items-center"
          >
            <span>Ver planos disponíveis</span>
            <ArrowRight className="ml-2 w-5 h-5" />
          </Link>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-16 px-4 bg-gray-50">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">Perguntas Frequentes</h2>
            <p className="text-xl text-gray-600">
              Tire suas dúvidas sobre a UaiSat TV
            </p>
          </div>

          <div className="space-y-6">
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <details className="group">
                <summary className="flex justify-between items-center p-6 cursor-pointer">
                  <h3 className="text-lg font-semibold">Quais dispositivos são compatíveis com a UaiSat TV?</h3>
                  <span className="transition-transform duration-300 group-open:rotate-180">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </span>
                </summary>
                <div className="px-6 pb-6 text-gray-600">
                  <p>A UaiSat TV é compatível com smartphones (Android e iOS), tablets, computadores (Windows e Mac), Smart TVs, Fire TV Stick, Roku e diversos outros dispositivos. Você pode acessar pelo navegador ou baixar o aplicativo disponível nas lojas de aplicativos.</p>
                </div>
              </details>
            </div>

            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <details className="group">
                <summary className="flex justify-between items-center p-6 cursor-pointer">
                  <h3 className="text-lg font-semibold">Posso assistir em mais de um dispositivo ao mesmo tempo?</h3>
                  <span className="transition-transform duration-300 group-open:rotate-180">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </span>
                </summary>
                <div className="px-6 pb-6 text-gray-600">
                  <p>Sim, você pode assistir em até 2 dispositivos simultaneamente com a mesma conta.</p>
                </div>
              </details>
            </div>

            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <details className="group">
                <summary className="flex justify-between items-center p-6 cursor-pointer">
                  <h3 className="text-lg font-semibold">Preciso de internet para assistir à UaiSat TV?</h3>
                  <span className="transition-transform duration-300 group-open:rotate-180">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </span>
                </summary>
                <div className="px-6 pb-6 text-gray-600">
                  <p>Sim, você precisa de uma conexão com a internet para assistir à UaiSat TV. Recomendamos uma velocidade mínima de 5 Mbps para uma experiência sem interrupções.</p>
                </div>
              </details>
            </div>

            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <details className="group">
                <summary className="flex justify-between items-center p-6 cursor-pointer">
                  <h3 className="text-lg font-semibold">Posso cancelar a UaiSat TV a qualquer momento?</h3>
                  <span className="transition-transform duration-300 group-open:rotate-180">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </span>
                </summary>
                <div className="px-6 pb-6 text-gray-600">
                  <p>Sim, você pode cancelar a UaiSat TV a qualquer momento, sem multa ou fidelidade. O serviço ficará disponível até o final do período já pago.</p>
                </div>
              </details>
            </div>

            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <details className="group">
                <summary className="flex justify-between items-center p-6 cursor-pointer">
                  <h3 className="text-lg font-semibold">A UaiSat TV consome dados do meu plano?</h3>
                  <span className="transition-transform duration-300 group-open:rotate-180">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </span>
                </summary>
                <div className="px-6 pb-6 text-gray-600">
                  <p>Sim, o streaming de TV consome dados do seu plano. Recomendamos utilizar uma conexão Wi-Fi para assistir por longos períodos e evitar o consumo excessivo de dados móveis.</p>
                </div>
              </details>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TV;