import React, { useState, useEffect } from 'react';
import { Search, MapPin, CheckCircle, Wifi, Signal, Loader2 } from 'lucide-react';
import { isCapitalCity, normalizeText, CAPITAL_CITIES } from '../lib/utils';

const CoverageSearch: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState({
    city: '',
    isCapital: false,
    hasSearched: false
  });
  const [loading, setLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState('');
  const [error, setError] = useState('');
  
  useEffect(() => {
    const fetchLocation = async (latitude: number, longitude: number, retryCount = 0): Promise<void> => {
      try {
        setLoading(true);
        setError('');
        
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 10000);

        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=pt-BR`,
          {
            headers: {
              'User-Agent': 'UaiSat Website (https://uaimovel.com.br)',
              'Accept': 'application/json'
            },
            signal: controller.signal
          }
        );

        clearTimeout(timeoutId);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        if (data && data.address) {
          const cityName = data.address.city || data.address.town || data.address.village || data.address.municipality;
          if (cityName) {
            setCurrentLocation(cityName);
            const isCapital = isCapitalCity(cityName);
            setSearchResult({
              city: cityName,
              isCapital,
              hasSearched: true
            });
          } else {
            throw new Error('Cidade não encontrada');
          }
        } else {
          throw new Error('Formato de dados inválido');
        }
      } catch (error) {
        console.error('Error fetching location:', error);
        
        // Retry up to 3 times with exponential backoff for network errors
        if (retryCount < 3 && (error instanceof TypeError || (error instanceof Error && error.name === 'AbortError'))) {
          const delay = Math.min(1000 * Math.pow(2, retryCount), 5000);
          await new Promise(resolve => setTimeout(resolve, delay));
          return fetchLocation(latitude, longitude, retryCount + 1);
        }
        
        setError('Não foi possível determinar sua localização. Por favor, digite sua cidade.');
      } finally {
        setLoading(false);
      }
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchLocation(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
          console.error('Geolocation error:', error);
          setError('Não foi possível acessar sua localização. Por favor, digite sua cidade.');
          setLoading(false);
        },
        {
          enableHighAccuracy: false, // Changed to false for faster response
          timeout: 30000, // Increased to 30 seconds
          maximumAge: 300000 // Cache location for 5 minutes
        }
      );
    } else {
      setError('Seu navegador não suporta geolocalização. Por favor, digite sua cidade.');
      setLoading(false);
    }
  }, []);
  
  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    const trimmedSearch = searchTerm.trim();
    if (trimmedSearch) {
      // Find exact capital match if exists
      const exactCapital = isCapitalCity(trimmedSearch) 
        ? CAPITAL_CITIES.find(capital => 
            normalizeText(capital) === normalizeText(trimmedSearch)
          )
        : null;

      setSearchResult({
        city: exactCapital || trimmedSearch,
        isCapital: isCapitalCity(trimmedSearch),
        hasSearched: true
      });
    }
  };
  
  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white p-8 rounded-2xl shadow-xl">
        {currentLocation && (
          <div className="mb-4 text-gray-600">
            <span>Você está em: </span>
            <span className="font-semibold">{currentLocation}</span>
          </div>
        )}

        {error && (
          <div className="mb-4 text-amber-600 bg-amber-50 p-4 rounded-lg">
            {error}
          </div>
        )}

        <form onSubmit={handleSearch} className="flex flex-col md:flex-row gap-4">
          <div className="flex-grow relative">
            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
              <MapPin className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              className="block w-full pl-12 pr-4 py-4 border border-gray-200 rounded-xl focus:ring-2 focus:ring-uai-teal focus:border-transparent text-lg transition-all duration-300"
              placeholder="Digite sua cidade"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="bg-uai-teal hover:bg-uai-dark text-white font-medium py-4 px-8 rounded-xl transition-all duration-300 flex items-center justify-center group whitespace-nowrap"
          >
            <Search className="w-5 h-5 mr-2 group-hover:scale-110 transition-transform duration-300" />
            <span>Verificar cobertura</span>
          </button>
        </form>
        
        {loading ? (
          <div className="mt-8 flex justify-center">
            <Loader2 className="w-8 h-8 animate-spin text-uai-teal" />
          </div>
        ) : (searchResult.hasSearched && (
          <div className="mt-8 space-y-6">
            <div className="p-6 bg-green-50 border border-green-200 rounded-xl">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <CheckCircle className="h-8 w-8 text-green-600" />
                </div>
                <div className="ml-4">
                  <h3 className="text-xl font-bold text-green-800">
                    {searchResult.isCapital 
                      ? `Cobertura disponível em ${searchResult.city}!`
                      : `Sinal disponível em ${searchResult.city}!`
                    }
                  </h3>
                  <div className="mt-4 space-y-3">
                    {searchResult.isCapital ? (
                      <>
                        <div className="flex items-center text-green-700">
                          <Signal className="h-6 w-6 mr-2" />
                          <span className="text-lg">5G - Ultra velocidade disponível</span>
                        </div>
                        <div className="flex items-center text-green-700">
                          <Wifi className="h-6 w-6 mr-2" />
                          <span className="text-lg">4G+ - Alta velocidade disponível</span>
                        </div>
                      </>
                    ) : (
                      <div className="flex items-center text-green-700">
                        <Wifi className="h-6 w-6 mr-2" />
                        <span className="text-lg">4G - Ótima cobertura disponível</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoverageSearch;