import React, { useState } from 'react';
import { 
  Upload, 
  FileText, 
  CheckCircle, 
  AlertCircle, 
  Loader2, 
  Download,
  Database,
  RefreshCw,
  Info,
  ArrowLeft
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '../../lib/supabase';
import toast from 'react-hot-toast';

interface ImportStats {
  total: number;
  inserted: number;
  errors: number;
  errorDetails: Array<{
    batch: number;
    error: string;
  }>;
}

const EsimImportPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [stats, setStats] = useState<ImportStats | null>(null);
  const [importUrl, setImportUrl] = useState('');

  const handleImport = async () => {
    if (!importUrl) {
      toast.error('Por favor, insira a URL de importação');
      return;
    }

    try {
      setLoading(true);
      setProgress(10);

      // Get the Supabase URL and anon key from environment variables
      const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
      const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

      if (!supabaseUrl || !supabaseAnonKey) {
        throw new Error('Configuração do Supabase não encontrada');
      }

      // Construct the import URL
      const importEndpoint = `${supabaseUrl}/functions/v1/import-esims`;

      // Call the Supabase Edge Function to process the import
      const response = await fetch(importEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${supabaseAnonKey}`
        },
        body: JSON.stringify({
          importUrl: importUrl,
          secret: 'your-secret-token' // This should be a secure token in production
        })
      });

      setProgress(50);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Falha ao importar eSIMs');
      }

      const result = await response.json();
      setStats(result);
      setProgress(100);
      
      if (result.inserted > 0) {
        toast.success(`Importação concluída: ${result.inserted} eSIMs importados`);
      } else {
        toast.error('Nenhum eSIM foi importado');
      }
    } catch (error) {
      console.error('Error importing eSIMs:', error);
      toast.error(error instanceof Error ? error.message : 'Erro ao importar eSIMs');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="flex items-center mb-6">
        <Link to="/admin/esim-management" className="mr-4 text-gray-600 hover:text-gray-900">
          <ArrowLeft className="w-6 h-6" />
        </Link>
        <h1 className="text-2xl font-bold">Importar e-SIMs</h1>
      </div>

      <div className="bg-white rounded-2xl shadow-sm p-6 mb-8">
        <div className="mb-6">
          <h2 className="text-xl font-bold mb-4">Importação via URL</h2>
          <p className="text-gray-600 mb-4">
            Insira a URL de importação para carregar os e-SIMs no sistema.
          </p>

          <div className="space-y-4">
            <div>
              <label htmlFor="importUrl" className="block text-sm font-medium text-gray-700 mb-2">
                URL de Importação
              </label>
              <input
                type="text"
                id="importUrl"
                value={importUrl}
                onChange={(e) => setImportUrl(e.target.value)}
                placeholder="https://fnijlaejxqfwlyjywvjd.supabase.co/functions/v1/import-esims"
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              />
            </div>
          </div>
        </div>

        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <div className="flex">
            <Info className="w-5 h-5 text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-blue-800 font-medium">Informações importantes</p>
              <p className="text-blue-700 text-sm">
                A URL de importação deve apontar para o endpoint da função de importação no Supabase.
                <br />
                Exemplo: https://fnijlaejxqfwlyjywvjd.supabase.co/functions/v1/import-esims
              </p>
            </div>
          </div>
        </div>

        {loading && (
          <div className="mb-6">
            <div className="flex justify-between mb-2">
              <span>Progresso</span>
              <span>{progress}%</span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2.5">
              <div 
                className="bg-uai-teal h-2.5 rounded-full" 
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </div>
        )}

        <button
          onClick={handleImport}
          disabled={loading || !importUrl}
          className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
              Importando...
            </>
          ) : (
            <>
              <Database className="w-5 h-5 mr-2" />
              Iniciar Importação
            </>
          )}
        </button>
      </div>

      {stats && (
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <h2 className="text-xl font-bold mb-4">Resultado da Importação</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-sm text-gray-500 mb-1">Total de e-SIMs</p>
              <p className="text-2xl font-bold">{stats.total}</p>
            </div>
            
            <div className="bg-green-50 p-4 rounded-lg">
              <p className="text-sm text-green-600 mb-1">Importados com sucesso</p>
              <p className="text-2xl font-bold text-green-700">{stats.inserted}</p>
            </div>
            
            <div className="bg-red-50 p-4 rounded-lg">
              <p className="text-sm text-red-600 mb-1">Erros</p>
              <p className="text-2xl font-bold text-red-700">{stats.errors}</p>
            </div>
          </div>
          
          {stats.errorDetails.length > 0 && (
            <div className="mt-4">
              <h3 className="font-bold mb-2">Detalhes dos erros:</h3>
              <div className="bg-red-50 p-4 rounded-lg">
                <ul className="list-disc pl-5 space-y-1">
                  {stats.errorDetails.map((error, index) => (
                    <li key={index} className="text-red-700">
                      Lote {error.batch}: {error.error}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          
          <div className="mt-6 flex justify-end">
            <button
              onClick={() => {
                setImportUrl('');
                setStats(null);
              }}
              className="flex items-center text-uai-teal hover:text-uai-dark"
            >
              <RefreshCw className="w-5 h-5 mr-2" />
              <span>Nova Importação</span>
            </button>
          </div>
        </div>
      )}

      <div className="mt-8 bg-gray-50 p-6 rounded-xl">
        <h2 className="text-xl font-bold mb-4">Outras opções de importação</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Link 
            to="/admin/esim-import"
            className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 flex flex-col items-start text-left group"
          >
            <div className="w-12 h-12 bg-uai-light rounded-xl flex items-center justify-center mb-4">
              <FileText className="w-6 h-6 text-uai-teal" />
            </div>
            <h3 className="text-lg font-bold mb-2">Importar via Arquivo CSV</h3>
            <p className="text-gray-600 mb-4">Importe e-SIMs a partir de um arquivo CSV com os dados necessários.</p>
          </Link>
          
          <Link 
            to="/admin/esim-batch-upload"
            className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 flex flex-col items-start text-left group"
          >
            <div className="w-12 h-12 bg-uai-light rounded-xl flex items-center justify-center mb-4">
              <Upload className="w-6 h-6 text-uai-teal" />
            </div>
            <h3 className="text-lg font-bold mb-2">Upload em Lote</h3>
            <p className="text-gray-600 mb-4">Faça upload de múltiplos e-SIMs de uma vez com processamento em lote.</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EsimImportPage;