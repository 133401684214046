import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Banner from '../components/Banner';
import PlanCard from '../components/PlanCard';
import CoverageSearch from '../components/CoverageSearch';
import Benefits from '../components/Benefits';
import AppDownload from '../components/AppDownload';
import Testimonials from '../components/Testimonials';
import { plans } from '../lib/data';
import { Tv, ArrowRight } from 'lucide-react';

const Home: React.FC = () => {
  const [activeTab, setActiveTab] = useState('controle');

  const controlePlans = plans.filter(plan => plan.type === 'controle' && plan.active);
  const prePagoPlans = plans.filter(plan => plan.type === 'prepago' && plan.active);

  return (
    <>
      <Banner />

      <section id="planos" className="py-16 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-4">Nossos Planos</h2>
          <p className="text-xl text-gray-600 text-center max-w-2xl mx-auto mb-8">
            Escolha o plano ideal para você com os melhores benefícios e cobertura nacional
          </p>
          
          <div className="flex justify-center mb-8">
            <div className="inline-flex rounded-xl shadow-sm" role="group">
              <button
                type="button"
                className={`px-8 py-4 text-lg font-medium rounded-l-xl transition-all duration-300 ${
                  activeTab === 'controle'
                    ? 'bg-uai-teal text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
                }`}
                onClick={() => setActiveTab('controle')}
              >
                Controle
              </button>
              <button
                type="button"
                className={`px-8 py-4 text-lg font-medium rounded-r-xl transition-all duration-300 ${
                  activeTab === 'prepago'
                    ? 'bg-uai-teal text-white'
                    : 'bg-white text-gray-700 hover:bg-gray-100'
                }`}
                onClick={() => setActiveTab('prepago')}
              >
                Pré-pago
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {activeTab === 'controle'
              ? controlePlans.map((plan) => (
                  <PlanCard key={plan.id} plan={plan} type="controle" />
                ))
              : prePagoPlans.map((plan) => (
                  <PlanCard key={plan.id} plan={plan} type="prepago" />
                ))}
          </div>
        </div>
      </section>

      {/* UaiSat TV Promo Section */}
      <section className="py-16 px-4 bg-gradient-to-r from-uai-dark to-uai-teal text-white">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-block px-4 py-2 bg-white/10 backdrop-blur-sm rounded-full text-white mb-6">
                <span className="flex items-center">
                  <Tv className="w-5 h-5 mr-2" />
                  Novidade
                </span>
              </div>
              <h2 className="text-4xl font-bold mb-6">Conheça a UaiSat TV</h2>
              <p className="text-xl mb-6">
                Mais de 50 canais de TV ao vivo para assistir quando e onde quiser. Adicione à sua assinatura por apenas R$20/mês.
              </p>
              <Link 
                to="/tv"
                className="bg-white text-uai-teal hover:bg-gray-100 font-bold py-4 px-8 rounded-xl text-lg transition-all duration-300 inline-flex items-center"
              >
                <span>Conhecer UaiSat TV</span>
                <ArrowRight className="ml-2 w-5 h-5" />
              </Link>
            </div>
            <div className="relative">
              <img 
                src="https://images.unsplash.com/photo-1593784991095-a205069470b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" 
                alt="UaiSat TV" 
                className="rounded-2xl shadow-xl"
              />
              <div className="absolute -bottom-6 -right-6 bg-white p-6 rounded-2xl shadow-xl">
                <div className="flex items-center">
                  <div className="bg-uai-light p-3 rounded-full mr-4">
                    <Tv className="w-8 h-8 text-uai-teal" />
                  </div>
                  <div>
                    <p className="text-gray-500 text-sm">A partir de</p>
                    <p className="font-bold text-xl text-uai-teal">R$ 20/mês</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="cobertura" className="py-16 px-4 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">Cobertura UaiSat</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Verifique a disponibilidade e qualidade do sinal na sua região
            </p>
          </div>
          <CoverageSearch />
        </div>
      </section>

      <Benefits />
      <AppDownload />
      <Testimonials />
    </>
  );
}

export default Home;