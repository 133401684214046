import React, { useState, useRef } from 'react';
import { 
  Upload, 
  FileText, 
  CheckCircle, 
  AlertCircle, 
  Loader2, 
  Download,
  Database,
  RefreshCw,
  Info
} from 'lucide-react';
import { supabase } from '../../lib/supabase';
import toast from 'react-hot-toast';

interface ImportStats {
  total: number;
  inserted: number;
  errors: number;
  errorDetails: Array<{
    batch: number;
    error: string;
  }>;
}

const EsimImport: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [stats, setStats] = useState<ImportStats | null>(null);
  const [importMethod, setImportMethod] = useState<'file' | 'paste'>('file');
  const [pastedData, setPastedData] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handlePastedDataChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPastedData(e.target.value);
  };

  const parseCSV = (text: string): Array<Record<string, string>> => {
    const lines = text.split('\n');
    if (lines.length === 0) return [];

    // Detect delimiter (comma or semicolon)
    const firstLine = lines[0];
    const delimiter = firstLine.includes(';') ? ';' : ',';
    
    // Parse header
    const headers = firstLine.split(delimiter).map(h => h.trim());
    
    // Parse data rows
    return lines.slice(1)
      .filter(line => line.trim() !== '') // Skip empty lines
      .map(line => {
        const values = line.split(delimiter);
        return headers.reduce((obj, header, index) => {
          obj[header] = values[index]?.trim() || '';
          return obj;
        }, {} as Record<string, string>);
      });
  };

  const processImport = async (data: Array<Record<string, string>>) => {
    try {
      setLoading(true);
      setProgress(0);
      setStats(null);

      // Map CSV data to eSIM format
      const esims = data.map(row => ({
        iccid: row.iccid || row.ICCID || '',
        activation_code: row.activation_code || row.code || row.ACTIVATION_CODE || '',
        server_address: row.server_address || row.SERVER_ADDRESS || 'sm-v4-064-a-gtm.pr.go-esim.com',
        status: row.status || row.STATUS || 'available'
      })).filter(esim => esim.iccid && esim.activation_code);

      if (esims.length === 0) {
        throw new Error('No valid eSIM data found in the file');
      }

      // Get function URL from Supabase
      const { data: { functionUrl } } = await supabase.functions.getUrl('import-esims');
      
      // Call the Supabase Edge Function to process the import
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${supabase.auth.getSession()}`
        },
        body: JSON.stringify({
          esims,
          secret: 'your-secret-token' // This should be a secure token in production
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to import eSIMs');
      }

      const result = await response.json();
      setStats(result);
      
      if (result.inserted > 0) {
        toast.success(`Importação concluída: ${result.inserted} eSIMs importados`);
      } else {
        toast.error('Nenhum eSIM foi importado');
      }
    } catch (error) {
      console.error('Error importing eSIMs:', error);
      toast.error(error instanceof Error ? error.message : 'Erro ao importar eSIMs');
    } finally {
      setLoading(false);
      setProgress(100);
    }
  };

  const handleImport = async () => {
    try {
      if (importMethod === 'file' && file) {
        const text = await file.text();
        const data = parseCSV(text);
        await processImport(data);
      } else if (importMethod === 'paste' && pastedData) {
        const data = parseCSV(pastedData);
        await processImport(data);
      } else {
        toast.error('Por favor, selecione um arquivo ou cole os dados');
      }
    } catch (error) {
      console.error('Error processing file:', error);
      toast.error(error instanceof Error ? error.message : 'Erro ao processar arquivo');
      setLoading(false);
    }
  };

  const downloadTemplate = () => {
    const csvContent = 'iccid,activation_code,server_address,status\n8955170220369010132,LPA:1$sm-v4-064-a-gtm.pr.go-esim.com$2F5E2A9049E8C354B5327A54009026A3,sm-v4-064-a-gtm.pr.go-esim.com,available';
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'esim_template.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Importar e-SIMs</h1>
        <button
          onClick={downloadTemplate}
          className="flex items-center text-uai-teal hover:text-uai-dark"
        >
          <Download className="w-5 h-5 mr-2" />
          <span>Baixar Template</span>
        </button>
      </div>

      <div className="bg-white rounded-2xl shadow-sm p-6 mb-8">
        <div className="mb-6">
          <div className="flex mb-4">
            <button
              onClick={() => setImportMethod('file')}
              className={`flex-1 py-2 px-4 text-center border-b-2 ${
                importMethod === 'file' 
                  ? 'border-uai-teal text-uai-teal font-medium' 
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              Importar Arquivo
            </button>
            <button
              onClick={() => setImportMethod('paste')}
              className={`flex-1 py-2 px-4 text-center border-b-2 ${
                importMethod === 'paste' 
                  ? 'border-uai-teal text-uai-teal font-medium' 
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
            >
              Colar Dados
            </button>
          </div>

          {importMethod === 'file' ? (
            <div className="space-y-4">
              <div 
                className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center hover:border-uai-teal transition-colors cursor-pointer"
                onClick={() => fileInputRef.current?.click()}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept=".csv,.txt"
                  className="hidden"
                />
                <FileText className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <p className="text-lg font-medium mb-2">
                  {file ? file.name : 'Clique para selecionar um arquivo CSV'}
                </p>
                <p className="text-sm text-gray-500">
                  {file ? `${(file.size / 1024).toFixed(2)} KB` : 'ou arraste e solte aqui'}
                </p>
              </div>

              {file && (
                <div className="flex items-center justify-between bg-gray-50 p-4 rounded-lg">
                  <div className="flex items-center">
                    <FileText className="w-5 h-5 text-uai-teal mr-2" />
                    <span className="font-medium">{file.name}</span>
                  </div>
                  <button
                    onClick={() => setFile(null)}
                    className="text-gray-500 hover:text-red-500"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="space-y-4">
              <textarea
                value={pastedData}
                onChange={handlePastedDataChange}
                placeholder="Cole os dados CSV aqui (incluindo o cabeçalho)..."
                className="w-full h-64 p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              />
            </div>
          )}
        </div>

        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <div className="flex">
            <Info className="w-5 h-5 text-blue-500 mr-2 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-blue-800 font-medium">Formato esperado</p>
              <p className="text-blue-700 text-sm">
                O arquivo deve ser um CSV com as colunas: iccid, activation_code, server_address, status.
                <br />
                Exemplo: 8955170220369010132,LPA:1$sm-v4-064-a-gtm.pr.go-esim.com$2F5E2A9049E8C354B5327A54009026A3,sm-v4-064-a-gtm.pr.go-esim.com,available
              </p>
            </div>
          </div>
        </div>

        <button
          onClick={handleImport}
          disabled={loading || (importMethod === 'file' && !file) || (importMethod === 'paste' && !pastedData)}
          className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-lg text-lg transition-all duration-300 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {loading ? (
            <>
              <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
              Importando... {progress > 0 && `${progress.toFixed(0)}%`}
            </>
          ) : (
            <>
              <Database className="w-5 h-5 mr-2" />
              Importar e-SIMs
            </>
          )}
        </button>
      </div>

      {stats && (
        <div className="bg-white rounded-2xl shadow-sm p-6">
          <h2 className="text-xl font-bold mb-4">Resultado da Importação</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
            <div className="bg-gray-50 p-4 rounded-lg">
              <p className="text-sm text-gray-500 mb-1">Total de e-SIMs</p>
              <p className="text-2xl font-bold">{stats.total}</p>
            </div>
            
            <div className="bg-green-50 p-4 rounded-lg">
              <p className="text-sm text-green-600 mb-1">Importados com sucesso</p>
              <p className="text-2xl font-bold text-green-700">{stats.inserted}</p>
            </div>
            
            <div className="bg-red-50 p-4 rounded-lg">
              <p className="text-sm text-red-600 mb-1">Erros</p>
              <p className="text-2xl font-bold text-red-700">{stats.errors}</p>
            </div>
          </div>
          
          {stats.errorDetails.length > 0 && (
            <div className="mt-4">
              <h3 className="font-bold mb-2">Detalhes dos erros:</h3>
              <div className="bg-red-50 p-4 rounded-lg">
                <ul className="list-disc pl-5 space-y-1">
                  {stats.errorDetails.map((error, index) => (
                    <li key={index} className="text-red-700">
                      Lote {error.batch}: {error.error}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          
          <div className="mt-6 flex justify-end">
            <button
              onClick={() => {
                setFile(null);
                setPastedData('');
                setStats(null);
              }}
              className="flex items-center text-uai-teal hover:text-uai-dark"
            >
              <RefreshCw className="w-5 h-5 mr-2" />
              <span>Nova Importação</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EsimImport;