import React, { useState, useEffect } from 'react';
import { 
  QrCode, 
  Search, 
  Filter, 
  CheckCircle, 
  XCircle, 
  Loader2, 
  AlertCircle,
  RefreshCw,
  Download,
  Calendar,
  Edit,
  Trash2,
  Plus,
  User,
  FileText
} from 'lucide-react';
import { supabase } from '../../lib/supabase';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';

interface Esim {
  id: string;
  iccid: string;
  activation_code: string;
  server_address: string;
  status: 'available' | 'assigned' | 'activated' | 'expired';
  assigned_to?: string;
  created_at: string;
  updated_at: string;
}

const EsimManagement: React.FC = () => {
  const [esims, setEsims] = useState<Esim[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState({
    start: '',
    end: ''
  });
  const [showDetails, setShowDetails] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    fetchEsims();
  }, [page, filterStatus, dateRange]);

  const fetchEsims = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // Count total records for pagination
      let countQuery = supabase
        .from('esims')
        .select('id', { count: 'exact', head: true });
      
      if (filterStatus) {
        countQuery = countQuery.eq('status', filterStatus);
      }
      
      if (dateRange.start) {
        countQuery = countQuery.gte('created_at', dateRange.start);
      }
      
      if (dateRange.end) {
        countQuery = countQuery.lte('created_at', dateRange.end);
      }
      
      const { count, error: countError } = await countQuery;
      
      if (countError) throw countError;
      
      setTotalPages(Math.ceil((count || 0) / pageSize));
      
      // Fetch paginated data
      let query = supabase
        .from('esims')
        .select('*')
        .order('created_at', { ascending: false })
        .range((page - 1) * pageSize, page * pageSize - 1);
      
      if (filterStatus) {
        query = query.eq('status', filterStatus);
      }
      
      if (dateRange.start) {
        query = query.gte('created_at', dateRange.start);
      }
      
      if (dateRange.end) {
        query = query.lte('created_at', dateRange.end);
      }
      
      const { data, error } = await query;
      
      if (error) throw error;
      
      setEsims(data || []);
    } catch (error) {
      console.error('Error fetching eSIMs:', error);
      setError('Erro ao carregar eSIMs');
      toast.error('Erro ao carregar eSIMs');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (id: string, status: string) => {
    try {
      const { error } = await supabase
        .from('esims')
        .update({ status })
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success(`Status atualizado para ${status}`);
      fetchEsims();
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Erro ao atualizar status');
    }
  };

  const handleDeleteEsim = async (id: string) => {
    if (!confirm('Tem certeza que deseja excluir este eSIM?')) return;
    
    try {
      const { error } = await supabase
        .from('esims')
        .delete()
        .eq('id', id);
      
      if (error) throw error;
      
      toast.success('eSIM excluído com sucesso');
      fetchEsims();
    } catch (error) {
      console.error('Error deleting eSIM:', error);
      toast.error('Erro ao excluir eSIM');
    }
  };

  const handleExportCSV = () => {
    try {
      // Filter esims based on search term
      const filteredEsims = esims.filter(esim => 
        esim.iccid.toLowerCase().includes(searchTerm.toLowerCase()) ||
        esim.status.toLowerCase().includes(searchTerm.toLowerCase())
      );
      
      // Create CSV content
      const headers = ['ICCID', 'Código de Ativação', 'Endereço do Servidor', 'Status', 'Data de Criação'];
      const rows = filteredEsims.map(esim => [
        esim.iccid,
        esim.activation_code,
        esim.server_address,
        esim.status,
        new Date(esim.created_at).toLocaleString('pt-BR')
      ]);
      
      const csvContent = [
        headers.join(','),
        ...rows.map(row => row.join(','))
      ].join('\n');
      
      // Create download link
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `esims_${new Date().toISOString().split('T')[0]}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      toast.success('Relatório exportado com sucesso!');
    } catch (error) {
      console.error('Error exporting CSV:', error);
      toast.error('Erro ao exportar relatório');
    }
  };

  const filteredEsims = esims.filter(esim => 
    esim.iccid.toLowerCase().includes(searchTerm.toLowerCase()) ||
    esim.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString('pt-BR');
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'available':
        return 'bg-green-100 text-green-800';
      case 'assigned':
        return 'bg-blue-100 text-blue-800';
      case 'activated':
        return 'bg-purple-100 text-purple-800';
      case 'expired':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-uai-teal" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <AlertCircle className="w-12 h-12 text-red-500 mb-4" />
        <p className="text-gray-600 mb-4">{error}</p>
        <button
          onClick={fetchEsims}
          className="flex items-center px-4 py-2 text-sm font-medium text-white bg-uai-teal rounded-lg hover:bg-uai-dark transition-colors"
        >
          <RefreshCw className="w-4 h-4 mr-2" />
          Tentar novamente
        </button>
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Gerenciamento de e-SIMs</h1>
        <div className="flex space-x-3">
          <Link 
            to="/admin/esim-import"
            className="bg-uai-teal hover:bg-uai-dark text-white px-4 py-2 rounded-lg flex items-center"
          >
            <Plus className="w-5 h-5 mr-2" />
            Importar
          </Link>
          <button 
            onClick={handleExportCSV}
            className="bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-lg flex items-center"
          >
            <Download className="w-5 h-5 mr-2" />
            Exportar
          </button>
          <div className="relative">
            <button className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-2 rounded-lg flex items-center">
              <Filter className="w-5 h-5 mr-2" />
              Filtrar
            </button>
            <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg p-4 z-10 hidden">
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Status
                </label>
                <select
                  value={filterStatus || ''}
                  onChange={(e) => setFilterStatus(e.target.value || null)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md"
                >
                  <option value="">Todos</option>
                  <option value="available">Disponível</option>
                  <option value="assigned">Atribuído</option>
                  <option value="activated">Ativado</option>
                  <option value="expired">Expirado</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Data Inicial
                </label>
                <div className="relative">
                  <input
                    type="date"
                    value={dateRange.start}
                    onChange={(e) => setDateRange({...dateRange, start: e.target.value})}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md pl-10"
                  />
                  <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Data Final
                </label>
                <div className="relative">
                  <input
                    type="date"
                    value={dateRange.end}
                    onChange={(e) => setDateRange({...dateRange, end: e.target.value})}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md pl-10"
                  />
                  <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                </div>
              </div>
              <button
                onClick={fetchEsims}
                className="w-full bg-uai-teal text-white py-2 rounded-md hover:bg-uai-dark"
              >
                Aplicar Filtros
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-2xl shadow-sm">
        <div className="p-6 border-b">
          <div className="flex items-center">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Buscar por ICCID..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-200 rounded-lg focus:ring-2 focus:ring-uai-teal focus:border-transparent"
              />
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ICCID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Data de Criação
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Ações
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {filteredEsims.map((esim) => (
                <React.Fragment key={esim.id}>
                  <tr className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{esim.iccid}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(esim.status)}`}>
                        {esim.status === 'available' ? 'Disponível' : 
                         esim.status === 'assigned' ? 'Atribuído' :
                         esim.status === 'activated' ? 'Ativado' : 'Expirado'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(esim.created_at)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button 
                        onClick={() => setShowDetails(showDetails === esim.id ? null : esim.id)}
                        className="text-uai-teal hover:text-uai-dark mr-3"
                      >
                        {showDetails === esim.id ? 'Ocultar' : 'Detalhes'}
                      </button>
                      <button 
                        onClick={() => handleDeleteEsim(esim.id)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </td>
                  </tr>
                  {showDetails === esim.id && (
                    <tr>
                      <td colSpan={4} className="px-6 py-4 bg-gray-50">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                          <div>
                            <h3 className="font-semibold text-lg mb-2">Informações do e-SIM</h3>
                            <div className="space-y-2">
                              <p><span className="font-medium">ICCID:</span> {esim.iccid}</p>
                              <p><span className="font-medium">Código de Ativação:</span> {esim.activation_code}</p>
                              <p><span className="font-medium">Endereço do Servidor:</span> {esim.server_address}</p>
                              <p><span className="font-medium">Status:</span> {esim.status}</p>
                              <p><span className="font-medium">Data de Criação:</span> {formatDate(esim.created_at)}</p>
                              <p><span className="font-medium">Última Atualização:</span> {formatDate(esim.updated_at)}</p>
                            </div>
                          </div>
                          <div>
                            <h3 className="font-semibold text-lg mb-2">Ações</h3>
                            <div className="space-y-2">
                              <button
                                onClick={() => handleUpdateStatus(esim.id, 'available')}
                                className={`px-4 py-2 rounded-lg mr-2 ${
                                  esim.status === 'available' 
                                    ? 'bg-green-100 text-green-800' 
                                    : 'bg-gray-100 text-gray-800 hover:bg-green-100 hover:text-green-800'
                                }`}
                              >
                                Disponível
                              </button>
                              <button
                                onClick={() => handleUpdateStatus(esim.id, 'assigned')}
                                className={`px-4 py-2 rounded-lg mr-2 ${
                                  esim.status === 'assigned' 
                                    ? 'bg-blue-100 text-blue-800' 
                                    : 'bg-gray-100 text-gray-800 hover:bg-blue-100 hover:text-blue-800'
                                }`}
                              >
                                Atribuído
                              </button>
                              <button
                                onClick={() => handleUpdateStatus(esim.id, 'activated')}
                                className={`px-4 py-2 rounded-lg mr-2 ${
                                  esim.status === 'activated' 
                                    ? 'bg-purple-100 text-purple-800' 
                                    : 'bg-gray-100 text-gray-800 hover:bg-purple-100 hover:text-purple-800'
                                }`}
                              >
                                Ativado
                              </button>
                              <button
                                onClick={() => handleUpdateStatus(esim.id, 'expired')}
                                className={`px-4 py-2 rounded-lg ${
                                  esim.status === 'expired' 
                                    ? 'bg-red-100 text-red-800' 
                                    : 'bg-gray-100 text-gray-800 hover:bg-red-100 hover:text-red-800'
                                }`}
                              >
                                Expirado
                              </button>
                            </div>
                            
                            <div className="mt-4">
                              <h4 className="font-medium mb-2">QR Code</h4>
                              <div className="bg-white p-4 rounded-lg border border-gray-200 inline-block">
                                <img 
                                  src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(esim.activation_code)}`}
                                  alt="e-SIM QR Code" 
                                  className="w-32 h-32"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        
        {/* Pagination */}
        {totalPages > 1 && (
          <div className="px-6 py-4 flex items-center justify-between border-t">
            <div>
              <p className="text-sm text-gray-700">
                Mostrando <span className="font-medium">{(page - 1) * pageSize + 1}</span> a <span className="font-medium">{Math.min(page * pageSize, (totalPages * pageSize))}</span> de <span className="font-medium">{totalPages * pageSize}</span> resultados
              </p>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => setPage(prev => Math.max(prev - 1, 1))}
                disabled={page === 1}
                className="px-3 py-1 rounded border border-gray-300 text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Anterior
              </button>
              {[...Array(totalPages)].map((_, i) => (
                <button
                  key={i}
                  onClick={() => setPage(i + 1)}
                  className={`px-3 py-1 rounded text-sm font-medium ${
                    page === i + 1 
                      ? 'bg-uai-teal text-white' 
                      : 'border border-gray-300 hover:bg-gray-50'
                  }`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => setPage(prev => Math.min(prev + 1, totalPages))}
                disabled={page === totalPages}
                className="px-3 py-1 rounded border border-gray-300 text-sm font-medium disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Próxima
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EsimManagement;