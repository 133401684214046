import axios from 'axios';
import axiosRetry from 'axios-retry';
import { API_CONFIG } from '../config';
import { supabase } from '../supabase';

// Create axios instance with retry configuration
const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Token': API_CONFIG.TOKEN,
    'Accept': 'application/json'
  },
  timeout: 30000 // 30 second timeout
});

// Configure retry behavior
axiosRetry(api, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000; // Wait 1s, 2s, 3s between retries
  },
  retryCondition: (error) => {
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || 
           (error.response?.status ? error.response.status >= 500 : false);
  }
});

export interface Esim {
  id: string;
  iccid: string;
  activation_code: string;
  server_address: string;
  status: 'available' | 'assigned' | 'activated' | 'expired';
  assigned_to?: string;
  created_at: string;
  updated_at: string;
}

// Get eSIM by ICCID
export const getEsimByIccid = async (iccid: string): Promise<Esim | null> => {
  try {
    const { data, error } = await supabase
      .from('esims')
      .select('*')
      .eq('iccid', iccid)
      .single();
    
    if (error) {
      if (error.code === 'PGRST116') {
        // PGRST116 is the error code for "no rows returned"
        return null;
      }
      throw error;
    }
    
    return data;
  } catch (error) {
    console.error('Error fetching eSIM:', error);
    throw error;
  }
};

// Get eSIMs assigned to user
export const getUserEsims = async (userId: string): Promise<Esim[]> => {
  try {
    const { data, error } = await supabase
      .from('esims')
      .select('*')
      .eq('assigned_to', userId)
      .order('created_at', { ascending: false });
    
    if (error) throw error;
    
    return data || [];
  } catch (error) {
    console.error('Error fetching user eSIMs:', error);
    throw error;
  }
};

// Assign eSIM to user
export const assignEsimToUser = async (iccid: string, userId: string): Promise<Esim> => {
  try {
    const { data, error } = await supabase
      .from('esims')
      .update({ 
        assigned_to: userId,
        status: 'assigned',
        updated_at: new Date().toISOString()
      })
      .eq('iccid', iccid)
      .eq('status', 'available')
      .select()
      .single();
    
    if (error) throw error;
    
    return data;
  } catch (error) {
    console.error('Error assigning eSIM:', error);
    throw error;
  }
};

// Update eSIM status
export const updateEsimStatus = async (iccid: string, status: 'available' | 'assigned' | 'activated' | 'expired'): Promise<Esim> => {
  try {
    const { data, error } = await supabase
      .from('esims')
      .update({ 
        status,
        updated_at: new Date().toISOString()
      })
      .eq('iccid', iccid)
      .select()
      .single();
    
    if (error) throw error;
    
    return data;
  } catch (error) {
    console.error('Error updating eSIM status:', error);
    throw error;
  }
};

// Bulk import eSIMs
export const bulkImportEsims = async (esims: Array<{
  iccid: string;
  activation_code: string;
  server_address?: string;
  status?: string;
}>): Promise<{ inserted: number; errors: number; errorDetails: any[] }> => {
  try {
    // Get function URL from Supabase
    const { data: { functionUrl } } = await supabase.functions.getUrl('import-esims');
    
    // Call the Supabase Edge Function to process the import
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${supabase.auth.getSession()}`
      },
      body: JSON.stringify({
        esims,
        secret: 'your-secret-token' // This should be a secure token in production
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to import eSIMs');
    }

    return await response.json();
  } catch (error) {
    console.error('Error importing eSIMs:', error);
    throw error;
  }
};

// Parse CSV file for eSIM import
export const parseEsimCsv = (csvText: string): Array<{
  iccid: string;
  activation_code: string;
  server_address?: string;
  status?: string;
}> => {
  const lines = csvText.split('\n');
  if (lines.length === 0) return [];

  // Detect delimiter (comma or semicolon)
  const firstLine = lines[0];
  const delimiter = firstLine.includes(';') ? ';' : ',';
  
  // Parse header
  const headers = firstLine.split(delimiter).map(h => h.trim().toLowerCase());
  
  // Check required columns
  if (!headers.includes('iccid') || !headers.includes('activation_code')) {
    throw new Error('CSV must contain at least iccid and activation_code columns');
  }
  
  // Parse data rows
  return lines.slice(1)
    .filter(line => line.trim() !== '') // Skip empty lines
    .map(line => {
      const values = line.split(delimiter);
      const esim: any = {};
      
      headers.forEach((header, index) => {
        if (values[index]) {
          esim[header] = values[index].trim();
        }
      });
      
      // Validate required fields
      if (!esim.iccid || !esim.activation_code) {
        throw new Error(`Invalid row: ${line} - missing required fields`);
      }
      
      return esim;
    });
};