import { create } from 'zustand';

export interface Plan {
  id: string;
  name: string;
  type: 'controle' | 'prepago';
  price: number;
  data: string;
  validity?: string;
  social: string;
  calls: string;
  benefits: string[];
  active: boolean;
  hasTV?: boolean;
}

export interface Banner {
  id: string;
  title: string;
  description: string;
  cta: string;
  image_url: string;
  color: string;
  display_order: number;
  active: boolean;
  link: string;
}

export const plans: Plan[] = [
  {
    id: '1',
    name: 'UaiSmart',
    type: 'controle',
    price: 29.90,
    data: '6GB',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '4GB Principal',
      '2GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '2',
    name: 'UaiTurbo',
    type: 'controle',
    price: 49.99,
    data: '15GB',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '11GB Principal',
      '4GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '3',
    name: 'UaiExtreme',
    type: 'controle',
    price: 79.90,
    data: '35GB',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '25GB Principal',
      '10GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '4',
    name: 'Recarga',
    type: 'prepago',
    price: 30.00,
    data: '6GB',
    validity: '30 dias',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '4GB Principal',
      '2GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '5',
    name: 'Recarga',
    type: 'prepago',
    price: 50.00,
    data: '15GB',
    validity: '30 dias',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '11GB Principal',
      '4GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  },
  {
    id: '6',
    name: 'Recarga',
    type: 'prepago',
    price: 90.00,
    data: '35GB',
    validity: '30 dias',
    social: 'WhatsApp e Waze grátis',
    calls: 'Ligações ilimitadas + 100 SMS',
    benefits: [
      '25GB Principal',
      '10GB Portabilidade',
      'Skeelo Light',
      'Desconto na Energia*',
      'Sem Fidelidade',
      'Sem Multa'
    ],
    active: true
  }
];

export const banners: Banner[] = [
  {
    id: '1',
    title: 'Planos Controle',
    description: 'A partir de R$29,90! WhatsApp ilimitado + Ligações grátis para qualquer operadora',
    cta: 'Conheça os Planos',
    image_url: 'https://cloud.uaisat.app.br/09/1.png',
    color: '#00a9a7',
    display_order: 1,
    active: true,
    link: '/#planos'
  },
  {
    id: '2',
    title: 'UaiExtreme 35GB',
    description: 'O plano perfeito para você que não vive sem internet! 35GB de internet + WhatsApp e Waze grátis',
    cta: 'Assine Agora',
    image_url: 'https://cloud.uaisat.app.br/09/foto-planos.png',
    color: '#6366f1',
    display_order: 2,
    active: true,
    link: 'https://equipe.uaimovel.com.br/solicitar-chip/'
  },
  {
    id: '3',
    title: 'Frete Grátis',
    description: 'Peça seu chip físico ou virtual (eSIM) com frete grátis para todo o Brasil!',
    cta: 'Peça seu Chip',
    image_url: 'https://cloud.uaisat.app.br/09/01.png',
    color: '#10b981',
    display_order: 3,
    active: true,
    link: 'https://equipe.uaimovel.com.br/solicitar-chip/'
  }
];

// TV Channels data
export interface TVChannel {
  id: string;
  name: string;
  logo: string;
  category: string;
  description?: string;
}

export const tvCategories = [
  'Abertos',
  'Filmes e Séries',
  'Esportes',
  'Notícias',
  'Documentários',
  'Infantil',
  'Variedades',
  'Música',
  'Religiosos',
  'Internacionais'
];

export const tvChannels: TVChannel[] = [
  // Abertos
  {
    id: '1',
    name: 'Globo',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-9.png.webp',
    category: 'Abertos',
    description: 'Canal aberto com programação variada, incluindo novelas, jornalismo e entretenimento.'
  },
  {
    id: '2',
    name: 'SBT',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-12.png.webp',
    category: 'Abertos',
    description: 'Canal aberto com programação variada, incluindo programas de auditório e novelas.'
  },
  {
    id: '3',
    name: 'Record',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-14.png.webp',
    category: 'Abertos',
    description: 'Canal aberto com programação variada, incluindo jornalismo, novelas e reality shows.'
  },
  {
    id: '4',
    name: 'Band',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-8.png.webp',
    category: 'Abertos',
    description: 'Canal aberto com programação variada, incluindo jornalismo e esportes.'
  },
  {
    id: '5',
    name: 'RedeTV',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-10.png.webp',
    category: 'Abertos',
    description: 'Canal aberto com programação variada, incluindo programas de auditório e jornalismo.'
  },
  {
    id: '6',
    name: 'TV Brasil',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-11.png.webp',
    category: 'Abertos',
    description: 'Canal público com programação educativa e cultural.'
  },
  {
    id: '7',
    name: 'TV Cultura',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-13.png.webp',
    category: 'Abertos',
    description: 'Canal público com programação educativa e cultural.'
  },
  {
    id: '8',
    name: 'TV Gazeta',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-15.png.webp',
    category: 'Abertos',
    description: 'Canal aberto com programação variada, incluindo jornalismo e entretenimento.'
  },
  {
    id: '9',
    name: 'Rede Vida',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-16.png.webp',
    category: 'Religiosos',
    description: 'Canal religioso com programação católica.'
  },
  {
    id: '10',
    name: 'TV Aparecida',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-17.png.webp',
    category: 'Religiosos',
    description: 'Canal religioso com programação católica.'
  },
  
  // Filmes e Séries
  {
    id: '11',
    name: 'TNT',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-18.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal de filmes e séries com programação variada, incluindo blockbusters e séries exclusivas.'
  },
  {
    id: '12',
    name: 'Warner',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-19.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal de filmes e séries com programação variada, incluindo conteúdo exclusivo da Warner Bros.'
  },
  {
    id: '13',
    name: 'Universal',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-20.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal de filmes e séries com programação variada, incluindo conteúdo exclusivo da Universal Pictures.'
  },
  {
    id: '14',
    name: 'Sony',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-21.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal de filmes e séries com programação variada, incluindo conteúdo exclusivo da Sony Pictures.'
  },
  {
    id: '15',
    name: 'Paramount',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-23.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal de filmes e séries com programação variada, incluindo conteúdo exclusivo da Paramount Pictures.'
  },
  {
    id: '16',
    name: 'Space',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-24.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal de filmes com programação variada, incluindo filmes de ação e suspense.'
  },
  {
    id: '17',
    name: 'Cinemax',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-25.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal de filmes com programação variada, incluindo filmes clássicos e contemporâneos.'
  },
  {
    id: '18',
    name: 'AXN',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-26.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal de filmes e séries com programação variada, incluindo séries de ação e suspense.'
  },
  
  // Esportes
  {
    id: '19',
    name: 'SporTV',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-88.png',
    category: 'Esportes',
    description: 'Canal de esportes com programação variada, incluindo futebol, vôlei, basquete e mais.'
  },
  {
    id: '20',
    name: 'ESPN',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-87.png.webp',
    category: 'Esportes',
    description: 'Canal de esportes com programação variada, incluindo futebol, basquete, NFL e mais.'
  },
  {
    id: '21',
    name: 'Fox Sports',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-86.png',
    category: 'Esportes',
    description: 'Canal de esportes com programação variada, incluindo futebol, UFC, automobilismo e mais.'
  },
  {
    id: '22',
    name: 'BandSports',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/08/band-sports.png',
    category: 'Esportes',
    description: 'Canal de esportes com programação variada, incluindo futebol, tênis, automobilismo e mais.'
  },
  {
    id: '23',
    name: 'Combate',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-85.png',
    category: 'Esportes',
    description: 'Canal especializado em esportes de luta, incluindo UFC, boxe e mais.'
  },
  
  // Notícias
  {
    id: '24',
    name: 'GloboNews',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-84.png.webp',
    category: 'Notícias',
    description: 'Canal de notícias 24 horas com cobertura nacional e internacional.'
  },
  {
    id: '25',
    name: 'CNN Brasil',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-83.png.webp',
    category: 'Notícias',
    description: 'Canal de notícias 24 horas com cobertura nacional e internacional.'
  },
  {
    id: '26',
    name: 'Record News',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-82.png.webp',
    category: 'Notícias',
    description: 'Canal de notícias 24 horas com cobertura nacional e internacional.'
  },
  {
    id: '27',
    name: 'BandNews',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-81.png.webp',
    category: 'Notícias',
    description: 'Canal de notícias 24 horas com cobertura nacional e internacional.'
  },
  
  // Documentários
  {
    id: '28',
    name: 'Discovery Channel',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-80.png.webp',
    category: 'Documentários',
    description: 'Canal de documentários com programação variada, incluindo ciência, tecnologia, história e mais.'
  },
  {
    id: '29',
    name: 'National Geographic',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-79.png',
    category: 'Documentários',
    description: 'Canal de documentários com programação variada, incluindo natureza, ciência, história e mais.'
  },
  {
    id: '30',
    name: 'History',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-78.png.webp',
    category: 'Documentários',
    description: 'Canal de documentários com programação variada, incluindo história, civilizações e mais.'
  },
  {
    id: '31',
    name: 'Animal Planet',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-77.png',
    category: 'Documentários',
    description: 'Canal de documentários com programação focada em animais e natureza.'
  },
  {
    id: '32',
    name: 'Discovery Turbo',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-76.png',
    category: 'Documentários',
    description: 'Canal de documentários com programação focada em automóveis, motos e máquinas.'
  },
  
  // Infantil
  {
    id: '33',
    name: 'Cartoon Network',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-34.png.webp',
    category: 'Infantil',
    description: 'Canal infantil com desenhos animados e séries para crianças e adolescentes.'
  },
  {
    id: '34',
    name: 'Disney Channel',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-33.png.webp',
    category: 'Infantil',
    description: 'Canal infantil com programação variada, incluindo séries e filmes da Disney.'
  },
  {
    id: '35',
    name: 'Nickelodeon',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-31.png.webp',
    category: 'Infantil',
    description: 'Canal infantil com desenhos animados e séries para crianças e adolescentes.'
  },
  {
    id: '36',
    name: 'Discovery Kids',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-30.png.webp',
    category: 'Infantil',
    description: 'Canal infantil com programação educativa para crianças.'
  },
  {
    id: '37',
    name: 'Gloob',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-29.png.webp',
    category: 'Infantil',
    description: 'Canal infantil com programação variada para crianças.'
  },
  
  // Variedades
  {
    id: '38',
    name: 'GNT',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-28.png.webp',
    category: 'Variedades',
    description: 'Canal de variedades com programação voltada para o público feminino.'
  },
  {
    id: '39',
    name: 'Multishow',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-27.png.webp',
    category: 'Variedades',
    description: 'Canal de variedades com programação de entretenimento, humor e música.'
  },
  {
    id: '40',
    name: 'Viva',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-1-4.png.webp',
    category: 'Variedades',
    description: 'Canal de variedades com programação nostálgica e reexibições de programas da Globo.'
  },
  {
    id: '41',
    name: 'Comedy Central',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-2.png',
    category: 'Variedades',
    description: 'Canal de humor com programação variada, incluindo séries, stand-up e mais.'
  },
  
  // Música
  {
    id: '42',
    name: 'MTV',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-38.png.webp',
    category: 'Música',
    description: 'Canal de música com programação variada, incluindo videoclipes, reality shows e mais.'
  },
  {
    id: '43',
    name: 'BIS',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-36.png.webp',
    category: 'Música',
    description: 'Canal de música com programação variada, incluindo shows, documentários e mais.'
  },
  
  // Canais de Áudio
  {
    id: '44',
    name: 'Super Rock',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/super-rock.png.webp',
    category: 'Música',
    description: 'Canal de áudio com programação de rock.'
  },
  {
    id: '45',
    name: 'Punk Rock',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/puck-rock.png.webp',
    category: 'Música',
    description: 'Canal de áudio com programação de punk rock.'
  },
  
  // Canais Internacionais
  {
    id: '46',
    name: 'CNN International',
    logo: 'https://cdn.tv.br/wp-content/uploads/2025/03/CNN-MONEY-01.png',
    category: 'Internacionais',
    description: 'Canal internacional de notícias 24 horas.'
  },
  {
    id: '47',
    name: 'BBC World News',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-171.png',
    category: 'Internacionais',
    description: 'Canal internacional de notícias da BBC.'
  },
  
  // Canais Premium
  {
    id: '48',
    name: 'HBO',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-189.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal premium de filmes e séries com conteúdo exclusivo da HBO.'
  },
  {
    id: '49',
    name: 'Telecine',
    logo: 'https://cdn.tv.br/wp-content/uploads/2023/09/Prancheta-188.png.webp',
    category: 'Filmes e Séries',
    description: 'Canal premium de filmes com programação variada, incluindo lançamentos e clássicos.'
  },
  
  // Canais Esportivos Adicionais
  {
    id: '50',
    name: 'RS Sport',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/10/RS-Sport-.png',
    category: 'Esportes',
    description: 'Canal de esportes com programação variada.'
  },
  {
    id: '51',
    name: 'RS Sport 2',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/10/RS-Sport-2.png',
    category: 'Esportes',
    description: 'Canal de esportes com programação variada.'
  },
  {
    id: '52',
    name: 'RS Sport 3',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/10/RS-Sport-3.png',
    category: 'Esportes',
    description: 'Canal de esportes com programação variada.'
  },
  
  // Canais de Streaming
  {
    id: '53',
    name: 'Clipstation',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/10/CLIPSTATION.png',
    category: 'Música',
    description: 'Canal de música com videoclipes variados.'
  },
  {
    id: '54',
    name: 'Clipstation Rock',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/10/CLIPSTATION-ROCK.png',
    category: 'Música',
    description: 'Canal de música com videoclipes de rock.'
  },
  {
    id: '55',
    name: 'Clipstation Trap',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/10/CLIPSTATION-TRAP.png',
    category: 'Música',
    description: 'Canal de música com videoclipes de trap.'
  },
  
  // Canais Adicionais
  {
    id: '56',
    name: 'Urban Movies',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/08/urban-movies.png',
    category: 'Filmes e Séries',
    description: 'Canal de filmes com programação variada.'
  },
  {
    id: '57',
    name: 'Urban Kids',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/08/urban-kids.png',
    category: 'Infantil',
    description: 'Canal infantil com programação variada.'
  },
  {
    id: '58',
    name: 'Urban Docs',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/08/urban-docs.png',
    category: 'Documentários',
    description: 'Canal de documentários com programação variada.'
  },
  {
    id: '59',
    name: 'Hallo',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/08/hallo.png',
    category: 'Variedades',
    description: 'Canal de variedades com programação diversificada.'
  },
  {
    id: '60',
    name: 'Hallo Anime',
    logo: 'https://cdn.tv.br/wp-content/uploads/2024/08/hallo-anime.png',
    category: 'Infantil',
    description: 'Canal de animes com programação variada.'
  }
];