export const LINKS = {
  CHIP_REQUEST: '/solicitar-chip',
  RECHARGE: '/app/recharge',
  TV: '/tv'
} as const;

export const API_CONFIG = {
  ENDPOINTS: {
    // Base URL
    BASE_URL: 'https://api.csmvno.com.br/api_producao',
    
    // Contact Form
    CONTACT: '/index.php?integracao/faleconosco',
    
    // Customer Management
    REGISTER: '/index.php?cliente/cadastrar',
    GET_CUSTOMER: '/index.php?cliente/consultar',
    UPDATE_CUSTOMER: '/index.php?cliente/atualizar',
    
    // Line Management
    ACTIVATE_LINE: '/index.php?ativasimcard',
    GET_LINES: '/index.php?assinantes',
    BLOCK_LINE: '/index.php?bloqueio',
    UNBLOCK_LINE: '/index.php?desbloqueio',
    
    // Recharge and Plans
    RECHARGE: '/index.php?integracao/recarga',
    GET_PLANS: '/index.php?planos',
    CHANGE_PLAN: '/index.php?mudancaplano',
    
    // Usage and History
    GET_USAGE: '/index.php?consumo',
    GET_HISTORY: '/index.php?historico',
    
    // Chip Request
    CHIP_REQUEST: '/index.php?integracao/compra',
    
    // Additional Endpoints
    GET_BALANCE: '/index.php?saldo',
    GET_INVOICES: '/index.php?faturas',
    GET_INVOICE_PDF: '/index.php?fatura/pdf',
    GET_NOTIFICATIONS: '/index.php?notificacoes',
    MARK_NOTIFICATION_READ: '/index.php?notificacao/lida',
    
    // Utility
    CEP: 'https://viacep.com.br/ws/:cep/json/'
  },
  TOKEN: 'eyJpZF9jbGllIjoiMjEifQ=='
} as const;

// SEO Configuration
export const SEO_CONFIG = {
  DEFAULT_TITLE: 'UaiSat - Operadora de Telefonia Móvel | Planos a partir de R$29,90',
  DEFAULT_DESCRIPTION: 'A melhor operadora de telefonia móvel com planos flexíveis e cobertura em todo o Brasil. WhatsApp ilimitado, ligações grátis e internet 4G/5G. Agora com UaiSat TV!',
  SITE_URL: 'https://novo.uaimovel.com.br',
  SOCIAL_IMAGE: 'https://cloud.uaisat.app.br/03/og-image.png',
  TWITTER_HANDLE: '@uaisat',
  KEYWORDS: 'operadora de celular, chip de celular, plano controle, plano pré-pago, internet 4G, internet 5G, whatsapp ilimitado, ligações ilimitadas, recarga celular, chip virtual, esim, portabilidade, UaiSat, telefonia móvel, UaiSat TV',
  GOOGLE_ANALYTICS_ID: 'G-XXXXXXXXXX'
};