import React, { useState, useEffect } from 'react';
import { 
  QrCode, 
  Search, 
  Smartphone, 
  CheckCircle, 
  AlertCircle, 
  Loader2, 
  ArrowRight,
  Apple,
  Smartphone as SmartphoneIcon,
  Download,
  Copy,
  ExternalLink
} from 'lucide-react';
import { Link } from 'react-router-dom';

interface VerificationResult {
  status: 'success' | 'error' | 'pending';
  message: string;
  qrCodeUrl?: string;
  activationCode?: string;
  serverAddress?: string;
  expiryDate?: string;
  iccid?: string;
}

const EsimVerification: React.FC = () => {
  const [iccid, setIccid] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<VerificationResult | null>(null);
  const [activeTab, setActiveTab] = useState<'qr' | 'manual'>('qr');
  const [selectedDevice, setSelectedDevice] = useState<'iphone' | 'android' | null>(null);

  // This would be replaced with an actual API call
  const verifyEsim = async (iccidValue: string): Promise<VerificationResult> => {
    // Simulate API call
    await new Promise(resolve => setTimeout(resolve, 1500));
    
    // For demo purposes, we'll return success for specific ICCIDs
    // In a real implementation, this would check against a database
    if (iccidValue === '8955170220369010132') {
      return {
        status: 'success',
        message: 'e-SIM encontrado e pronto para ativação!',
        activationCode: 'LPA:1$sm-v4-064-a-gtm.pr.go-esim.com$2F5E2A9049E8C354B5327A54009026A3',
        serverAddress: 'sm-v4-064-a-gtm.pr.go-esim.com',
        iccid: '8955170220369010132'
      };
    } else {
      return {
        status: 'error',
        message: 'e-SIM não encontrado. Verifique o ICCID e tente novamente.'
      };
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!iccid.trim()) {
      setResult({
        status: 'error',
        message: 'Por favor, digite o ICCID do seu e-SIM.'
      });
      return;
    }
    
    setLoading(true);
    setResult(null);
    
    try {
      const verificationResult = await verifyEsim(iccid);
      setResult(verificationResult);
      
      // Auto-select device type if verification is successful
      if (verificationResult.status === 'success') {
        setSelectedDevice('iphone');
      }
    } catch (error) {
      setResult({
        status: 'error',
        message: 'Ocorreu um erro ao verificar o e-SIM. Por favor, tente novamente.'
      });
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    alert('Copiado para a área de transferência!');
  };

  const renderActivationInstructions = () => {
    if (!result || result.status !== 'success') return null;

    const iPhoneInstructions = [
      'Vá em Ajustes &gt; Celular',
      'Toque em "Adicionar Plano Celular"',
      'Escaneie o QR Code ou use a entrada manual',
      'Siga as instruções na tela para completar a ativação'
    ];

    const androidInstructions = [
      'Vá em Configurações &gt; Rede e Internet &gt; SIM',
      'Toque em "Adicionar operadora" ou "Adicionar eSIM"',
      'Escaneie o QR Code ou use a entrada manual',
      'Siga as instruções na tela para completar a ativação'
    ];

    // Generate QR code URL using GoQR.me API
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${encodeURIComponent(result.activationCode || '')}`;

    return (
      <div className="mt-8">
        <h3 className="text-2xl font-bold mb-6">Instruções de Ativação</h3>
        
        <div className="flex mb-6 bg-gray-100 rounded-lg p-1">
          <button
            onClick={() => setSelectedDevice('iphone')}
            className={`flex-1 py-3 px-4 rounded-lg flex items-center justify-center ${
              selectedDevice === 'iphone' ? 'bg-white shadow-sm' : 'hover:bg-white/50'
            }`}
          >
            <Apple className="w-5 h-5 mr-2" />
            <span>iPhone</span>
          </button>
          <button
            onClick={() => setSelectedDevice('android')}
            className={`flex-1 py-3 px-4 rounded-lg flex items-center justify-center ${
              selectedDevice === 'android' ? 'bg-white shadow-sm' : 'hover:bg-white/50'
            }`}
          >
            <SmartphoneIcon className="w-5 h-5 mr-2" />
            <span>Android</span>
          </button>
        </div>
        
        {selectedDevice && (
          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
            <h4 className="font-bold text-lg mb-4">
              {selectedDevice === 'iphone' ? 'Instruções para iPhone' : 'Instruções para Android'}
            </h4>
            
            <div className="mb-6">
              <div className="flex mb-4">
                <button
                  onClick={() => setActiveTab('qr')}
                  className={`flex-1 py-2 px-4 text-center border-b-2 ${
                    activeTab === 'qr' 
                      ? 'border-uai-teal text-uai-teal font-medium' 
                      : 'border-transparent text-gray-500 hover:text-gray-700'
                  }`}
                >
                  Via QR Code
                </button>
                <button
                  onClick={() => setActiveTab('manual')}
                  className={`flex-1 py-2 px-4 text-center border-b-2 ${
                    activeTab === 'manual' 
                      ? 'border-uai-teal text-uai-teal font-medium' 
                      : 'border-transparent text-gray-500 hover:text-gray-700'
                  }`}
                >
                  Entrada Manual
                </button>
              </div>
              
              {activeTab === 'qr' ? (
                <div>
                  <ol className="space-y-3 mb-6">
                    {(selectedDevice === 'iphone' ? iPhoneInstructions : androidInstructions).map((step, index) => (
                      <li key={index} className="flex items-start">
                        <div className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                          {index + 1}
                        </div>
                        <span dangerouslySetInnerHTML={{ __html: step }} />
                      </li>
                    ))}
                  </ol>
                  
                  <div className="flex justify-center">
                    <div className="bg-white p-4 rounded-xl shadow-sm border border-gray-200">
                      {/* QR Code Image using GoQR.me API */}
                      <img 
                        src={qrCodeUrl}
                        alt="e-SIM QR Code" 
                        className="w-64 h-64"
                      />
                      <div className="mt-4 flex justify-center">
                        <a 
                          href={qrCodeUrl}
                          download="esim-qrcode.png"
                          className="flex items-center text-uai-teal hover:text-uai-dark"
                        >
                          <Download className="w-4 h-4 mr-1" />
                          <span>Baixar QR Code</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <ol className="space-y-3 mb-6">
                    {selectedDevice === 'iphone' ? (
                      <>
                        <li className="flex items-start">
                          <div className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                            1
                          </div>
                          <span dangerouslySetInnerHTML={{ __html: 'Vá em Ajustes &gt; Celular' }} />
                        </li>
                        <li className="flex items-start">
                          <div className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                            2
                          </div>
                          <span>Toque em "Adicionar Plano Celular"</span>
                        </li>
                        <li className="flex items-start">
                          <div className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                            3
                          </div>
                          <span>Toque em "Digite Detalhes Manualmente"</span>
                        </li>
                        <li className="flex items-start">
                          <div className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                            4
                          </div>
                          <span>Digite o endereço SM-DP+ e o código de ativação abaixo</span>
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="flex items-start">
                          <div className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                            1
                          </div>
                          <span dangerouslySetInnerHTML={{ __html: 'Vá em Configurações &gt; Rede e Internet &gt; SIM' }} />
                        </li>
                        <li className="flex items-start">
                          <div className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                            2
                          </div>
                          <span>Toque em "Adicionar operadora" ou "Adicionar eSIM"</span>
                        </li>
                        <li className="flex items-start">
                          <div className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                            3
                          </div>
                          <span>Selecione "Inserir manualmente" ou "Não tem um código QR?"</span>
                        </li>
                        <li className="flex items-start">
                          <div className="flex-shrink-0 w-6 h-6 rounded-full bg-uai-light text-uai-teal flex items-center justify-center mr-3 mt-0.5">
                            4
                          </div>
                          <span>Digite o endereço do servidor e o código de ativação abaixo</span>
                        </li>
                      </>
                    )}
                  </ol>
                  
                  <div className="space-y-4">
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Endereço do Servidor (SM-DP+)
                      </label>
                      <div className="flex">
                        <input
                          type="text"
                          value={result.serverAddress}
                          readOnly
                          className="flex-1 px-4 py-2 border border-gray-300 rounded-l-lg bg-white focus:outline-none focus:ring-2 focus:ring-uai-teal"
                        />
                        <button
                          onClick={() => copyToClipboard(result.serverAddress || '')}
                          className="bg-uai-teal text-white px-4 py-2 rounded-r-lg hover:bg-uai-dark transition-colors"
                        >
                          <Copy className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 p-4 rounded-lg">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Código de Ativação
                      </label>
                      <div className="flex">
                        <input
                          type="text"
                          value={result.activationCode ? result.activationCode.replace('LPA:1$sm-v4-064-a-gtm.pr.go-esim.com$', '') : ''}
                          readOnly
                          className="flex-1 px-4 py-2 border border-gray-300 rounded-l-lg bg-white focus:outline-none focus:ring-2 focus:ring-uai-teal"
                        />
                        <button
                          onClick={() => copyToClipboard(result.activationCode ? result.activationCode.replace('LPA:1$sm-v4-064-a-gtm.pr.go-esim.com$', '') : '')}
                          className="bg-uai-teal text-white px-4 py-2 rounded-r-lg hover:bg-uai-dark transition-colors"
                        >
                          <Copy className="w-5 h-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        
        <div className="mt-6 bg-blue-50 border border-blue-100 rounded-lg p-4 flex items-start">
          <div className="flex-shrink-0 mt-1">
            <AlertCircle className="w-5 h-5 text-blue-500" />
          </div>
          <div className="ml-3">
            <p className="text-blue-800">
              Precisa de ajuda para ativar seu e-SIM? Consulte nosso{' '}
              <Link to="/ativar-esim" className="text-blue-600 hover:text-blue-800 underline">
                guia completo de ativação
              </Link>{' '}
              ou entre em contato com nosso{' '}
              <Link to="/contato" className="text-blue-600 hover:text-blue-800 underline">
                suporte técnico
              </Link>.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-50 min-h-screen py-16 px-4">
      <div className="container mx-auto max-w-4xl">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold mb-4">Verificação e Ativação de e-SIM</h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Digite o ICCID do seu e-SIM para verificar o status e obter instruções de ativação
          </p>
        </div>
        
        <div className="bg-white rounded-2xl shadow-xl p-8 mb-8">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="iccid" className="block text-lg font-medium text-gray-700 mb-2">
                ICCID do e-SIM
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <QrCode className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  id="iccid"
                  name="iccid"
                  value={iccid}
                  onChange={(e) => setIccid(e.target.value)}
                  placeholder="Digite o ICCID de 19 ou 20 dígitos"
                  className="block w-full pl-10 pr-4 py-4 border border-gray-300 rounded-xl focus:ring-2 focus:ring-uai-teal focus:border-transparent text-lg"
                  maxLength={20}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                O ICCID é um código de 19 ou 20 dígitos que identifica seu e-SIM. Você pode encontrá-lo no e-mail de confirmação da compra.
              </p>
            </div>
            
            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-uai-teal hover:bg-uai-dark text-white font-bold py-4 px-8 rounded-xl text-lg transition-all duration-300 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? (
                  <>
                    <Loader2 className="animate-spin -ml-1 mr-3 h-5 w-5" />
                    Verificando...
                  </>
                ) : (
                  <>
                    <Search className="w-5 h-5 mr-2" />
                    Verificar e-SIM
                  </>
                )}
              </button>
            </div>
          </form>
          
          {/* Result Section */}
          {result && (
            <div className={`mt-8 p-6 rounded-xl ${
              result.status === 'success' 
                ? 'bg-green-50 border border-green-100' 
                : 'bg-red-50 border border-red-100'
            }`}>
              <div className="flex items-start">
                <div className="flex-shrink-0 mt-0.5">
                  {result.status === 'success' ? (
                    <CheckCircle className="h-6 w-6 text-green-500" />
                  ) : (
                    <AlertCircle className="h-6 w-6 text-red-500" />
                  )}
                </div>
                <div className="ml-3">
                  <h3 className={`text-lg font-medium ${
                    result.status === 'success' ? 'text-green-800' : 'text-red-800'
                  }`}>
                    {result.status === 'success' ? 'e-SIM Verificado' : 'Erro na Verificação'}
                  </h3>
                  <p className={`mt-1 text-sm ${
                    result.status === 'success' ? 'text-green-700' : 'text-red-700'
                  }`}>
                    {result.message}
                  </p>
                  
                  {result.status === 'success' && (
                    <>
                      {result.iccid && (
                        <p className="mt-2 text-sm text-green-700">
                          <span className="font-medium">ICCID:</span> {result.iccid}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          
          {/* Activation Instructions */}
          {result && result.status === 'success' && renderActivationInstructions()}
        </div>
        
        {/* Help Section */}
        <div className="bg-white rounded-2xl shadow-xl p-8">
          <h2 className="text-2xl font-bold mb-6">Precisa de ajuda?</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gray-50 p-6 rounded-xl">
              <h3 className="font-bold text-lg mb-3 flex items-center">
                <QrCode className="w-5 h-5 text-uai-teal mr-2" />
                Não encontrou seu e-SIM?
              </h3>
              <p className="text-gray-600 mb-4">
                Se você não conseguir encontrar seu e-SIM com o ICCID fornecido, entre em contato com nosso suporte.
              </p>
              <Link 
                to="/contato"
                className="text-uai-teal hover:text-uai-dark font-medium flex items-center"
              >
                <span>Fale com o suporte</span>
                <ArrowRight className="w-4 h-4 ml-1" />
              </Link>
            </div>
            
            <div className="bg-gray-50 p-6 rounded-xl">
              <h3 className="font-bold text-lg mb-3 flex items-center">
                <Smartphone className="w-5 h-5 text-uai-teal mr-2" />
                Guia completo de ativação
              </h3>
              <p className="text-gray-600 mb-4">
                Consulte nosso guia detalhado com instruções para todos os modelos de smartphones.
              </p>
              <Link 
                to="/ativar-esim"
                className="text-uai-teal hover:text-uai-dark font-medium flex items-center"
              >
                <span>Ver guia completo</span>
                <ArrowRight className="w-4 h-4 ml-1" />
              </Link>
            </div>
          </div>
          
          <div className="mt-8 text-center">
            <p className="text-gray-600 mb-4">Ainda não tem um e-SIM UaiSat?</p>
            <Link 
              to="/esim"
              className="bg-uai-teal hover:bg-uai-dark text-white font-bold py-3 px-6 rounded-xl transition-all duration-300 inline-flex items-center"
            >
              <span>Adquirir e-SIM</span>
              <ExternalLink className="w-4 h-4 ml-2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsimVerification;